import React, { FC, useState } from "react";
import "./WidgetRunner.css";

// подключение внешнего .js
const loadScript = (scriptUrl: string, callback: () => void) => {
  const scriptLoaded =
    document.querySelectorAll(`[src="${scriptUrl}"]`).length > 0;
  if (!scriptLoaded) {
    const script = document.createElement("script");
    script.src = scriptUrl;
    script.async = true;
    script.onload = () => callback();
    document.body.appendChild(script);
  } else {
    callback();
  }
};

export const WidgetRunnerForm: FC = () => {
  const [widgetUrl, setWidgetUrl] = useState<string | undefined>(
    process.env.REACT_APP_WIDGET_URL
  );
  const [orderCode, setOrderCode] = useState<string | undefined>();

  const runWidget = () => {
    if (widgetUrl && orderCode) {
      loadScript(widgetUrl, () => {
        // @ts-ignore
        window.showVtbAcqWidget({ orderId: orderCode });
      });
    } else {
      alert("widgetUrl и orderCode обязательны");
    }
  };

  return (
    <div className="WidgetRunner">
      <input
        type="text"
        placeholder="ссылка на widget.js"
        value={widgetUrl}
        onChange={(event) => setWidgetUrl(event.target.value)}
      />
      <input
        type="text"
        placeholder="orderCode"
        value={orderCode}
        onChange={(event) => setOrderCode(event.target.value)}
      />
      <button onClick={runWidget}>запустить виджет</button>
    </div>
  );
};
