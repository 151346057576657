import React from 'react';
import { WidgetRunnerForm } from "./components/WidgetRunnerForm/WidgetRunnerForm";

function App() {
  return (
    <WidgetRunnerForm />
  );
}

export default App;
